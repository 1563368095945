/* Style for form container */
form {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style for form div containers */
div {
    margin-bottom: 15px;
}

/* Style for form labels */
label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

/* Style for form inputs */
input[type="text"],
input[type="number"] {
    max-width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Style for submit button */
button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

/* Style for submit button on hover */
button[type="submit"]:hover {
    background-color: #0056b3;
}
