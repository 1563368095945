
body {
    font-family: sans-serif;
   ;
    background-color: cornflowerblue;
}

.container {
    margin: 0px auto 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.product-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}

.product-image {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    text-align: center;
}

.product-image img {
    max-width: 100%;
    max-height: 100%;
}

.product-title {
    font-size: 16px;
    margin: 0;
    text-align: center;
}

.product-price {
    font-size: 14px;
    margin: 0;
    text-align: center;
}

.add-to-cart-btn,
.view-btn {
    background-color: cornflowerblue;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.25s ease;
}

.add-to-cart-btn:hover,
.view-btn:hover { 
    opacity: 0.8;
}
